import React, {lazy} from "react";
import {
    Edit3,
    User,
    HelpCircle,
    LifeBuoy,
    Settings,
    LogOut,
    DollarSign,
    AlertOctagon,
    Command,
   
} from "react-feather";
import {
    DropdownToggle,
    Dropdown
} from "../../ui/dropdown/dropdown";
import{
    Avatar,
    AvatarInitial,
}from "../../ui/avatar/avatar"
import {
    StyledDropMenu,
    StyledAuthorName,
    StyledAuthorRole,
    StyledDropItem,
    StyledDivider,
    StyledAvatar,
} from "./style";
import Button from '@material-ui/core/Button';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {finanslink} from "../../../../appconfig";
const roles = localStorage.getItem('roles');
 



const Help = lazy(() => import("../../../../pages/help/Help"));
// "./pages/help/Help"

const logoutChannel = new BroadcastChannel('logout')

export const logOut = () => {
    logoutChannel.postMessage("Logout")
    localStorage.removeItem("token")
    window.location.href =window.location.origin + "/login";
}

export const logoutAllTabs = () =>{
    logoutChannel.onmessage = () => {
        logOut();
        logoutChannel.close();
    }
}
const ProfileDropdown: React.FC = () => {

    // const logOut=()=>{

    //     localStorage.removeItem('token');    
    //   }


    return (
        <Dropdown direction="down" className="dropdown-profile">
            <DropdownToggle variant="texted">
                <StyledAvatar size="sm">
                    <AvatarInitial>RDC</AvatarInitial>
                </StyledAvatar>
            </DropdownToggle>
            <StyledDropMenu>
                <Avatar size="lg">
                    <AvatarInitial>RDC</AvatarInitial>
                </Avatar>
                <StyledAuthorName>KullanıcıAdı</StyledAuthorName>
                <StyledAuthorRole>KullanıcıRolü</StyledAuthorRole>
                <StyledDropItem path="/profile">
                    <Edit3 size="24" />
                    Profili incele
                </StyledDropItem>
                <StyledDivider />
                <StyledDropItem
                    
                    path="/help"
                    mt="10px"
                >
                    <HelpCircle size="24" />
                    Yardım
                </StyledDropItem>
                <StyledDropItem path="/profile" mt="10px">
                    <Command size="24" />
                    Hesap ayarları
                </StyledDropItem>
                <StyledDropItem path="/profile" mt="10px">
                    <AlertOctagon size="24" />
                    Gizlilik ayarları
                </StyledDropItem>
                {roles?.includes('Finance') && (

                <StyledDropItem path={finanslink} mt="10px">
                    <DollarSign size="24" />
                    Finans
                </StyledDropItem>)}               
                <StyledDropItem path="/login" mt="10px">
                <Button onClick ={logOut}
          variant="contained"
          color="default"
          startIcon={<ExitToAppIcon />}
          >
          ÇIKIŞ YAP
          </Button>
                </StyledDropItem>
            </StyledDropMenu>
        </Dropdown>
    );
};

export default ProfileDropdown;



