import axios from 'axios';
import React, { Suspense, lazy, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, useHistory } from "react-router-dom";
import { Link, Redirect } from "react-router-dom";
import './App.css';
import Preloader from './layouts/preloader/Preloader';
import NotFound from './pages/notFound/NotFound';
import PersonnelRegistration from './pages/personnellRegistration/PersonnelRegistration';
import { logoutAllTabs } from './components/src/header/profile-dropdown';
const Login = lazy(() => import("./pages/login/Login"));
const Help = lazy(() => import("./pages/help/Help"));
const Profile = lazy(() => import("./pages/profile-view/profile-view"));
const Home = lazy(() => import("./pages/home"));
const CurrentDebt = lazy(() => import("./pages/currentDebt/CurrentDebt"));
const CostCenter = lazy(() => import("./pages/costCenter/CostCenter"));
const AddCostCenter = lazy(()=>import("./pages/addCostCenter/AddCostCenter"))
const LeavingPersonnel = lazy(()=>import("./pages/leavingPersonnel/LeavingPersonnel"))
const ProjectAssignment = lazy(()=>import("./pages/projectAssignment/ProjectAssignment"))
const NewJobStart = lazy(()=>import("./pages/newJobStart/NewJobStart"))
const Teknopark = lazy(()=>import("./pages/teknopark/Teknopark"))
const TeknoparkRapor = lazy(()=>import("./pages/teknoparkRapor/TeknoparkRapor"))


const AddTeknopark = lazy(()=>import("./pages/AddTeknopark/AddTeknopark"))
const Project = lazy(()=>import("./pages/project/Project"))
const TeknoparkAddNotCustomer = lazy(() => import("./pages/teknoparkAddNotCustomer/TeknoparkAddNotCustomer"));
const Personnel = lazy(()=>import("./pages/personnel/Personnel"))

const App: React.FC = () => {

  useEffect(() => {
    logoutAllTabs ()
  }, [])
  const token = localStorage.getItem('token')
  const tokenDuration = localStorage.getItem('tokenDuration')
  
  if (token == null){
    logoutAllTabs()
  }
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(true);
  useEffect(() => {
    if (token != null) {
      setIsLoggedIn(true);
    }
    else {
      setIsLoggedIn(false);
    }
  }, [token]);
  const [tokenDate, setTokenDate] = useState(new Date(tokenDuration?.split(' ')[0] ?? ''));
  const history = useHistory();

 

    return (
      <div>
        <Router>

          <Suspense fallback={<Preloader />}>
            <Switch >
              <Route exact path="/" component={() => isLoggedIn ? <Home /> : <Redirect to='/login' />} />
              <Route exact path="/personnelregister" component={() => isLoggedIn ? <PersonnelRegistration /> : <Redirect to='/login' />} />
              <Route exact path="/help" component={() => isLoggedIn ? <Help /> : <Redirect to='/login' />} />
              <Route exact path="/profile" component={() => isLoggedIn ? <Profile /> : <Redirect to='/login' />} />
              <Route exact path="/currentDebt" component={() => isLoggedIn ? <CurrentDebt /> : <Redirect to='/login' />} />
              <Route exact path="/costCenter" component={() => isLoggedIn ? <CostCenter /> : <Redirect to='/login' />} />
              <Route exact path="/addCostCenter" component={() => isLoggedIn ? <AddCostCenter /> : <Redirect to='/login' />} />
              <Route exact path="/leavingPersonnel" component={() => isLoggedIn ? <LeavingPersonnel /> : <Redirect to='/login' />} />
              <Route exact path="/teknoparkAddNotCustomer" component={() => isLoggedIn ? <TeknoparkAddNotCustomer /> : <Redirect to='/login' />} /> 
              <Route exact path="/projectAssignment" component={() => isLoggedIn ? <ProjectAssignment /> : <Redirect to='/login' />} />
              <Route exact path="/newJobStart" component={() => isLoggedIn ? <NewJobStart /> : <Redirect to='/login' />} />
              <Route exact path="/teknopark" component={() => isLoggedIn ? <Teknopark /> : <Redirect to='/login' />} />
              <Route exact path="/technoparkReport" component={() => isLoggedIn ? <TeknoparkRapor /> : <Redirect to='/login' />} />
              <Route exact path="/project" component={() => isLoggedIn ? <Project /> : <Redirect to='/login' />} />
              <Route exact path="/personnel" component={() => isLoggedIn ? <Personnel /> : <Redirect to='/login' />} />
              <Route exact path="/login" component ={() => isLoggedIn == false ? <Login  /> : <Redirect to='/' />} />
              <Route component={NotFound} />
            </Switch>
          </Suspense>
        </Router>
      </div>

    );
  // else return (
  //   <div>
  //     <Router>
  //       <Suspense fallback={<Preloader />}>
  //         <Switch>
  //         <Route exact path="/" component={Login} />
  //           {/* </Switch>   
  //   <Switch> */}
  //           {/* <Route component={NotFound}/> */}
  //         </Switch>
  //       </Suspense>
  //     </Router>
  //   </div>
  // )
}

export default App;


