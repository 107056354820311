import {
    Calendar,
    Users,
    FileText,
    User,
    TrendingUp,
    Monitor,
    Bookmark,
    Tag,
    DollarSign,
    Star,
    Award,
    Search,
} from "react-feather";
const roles = localStorage.getItem('roles');

const menus = [
    {
        id: 1,
        label: "Teknopark",
        url: "/",
        Icon: Search,
        submenu: [
            roles?.includes('Technopark') ?
                {
                    id: 11,
                    label: "Teknopark",
                    url: "/teknopark",
                } : {
                    id: 11,
                    label: "",
                    url: "/",
                },
            roles?.includes('TechnoparkProject') ?
                {
                    id: 12,
                    label: "Proje",
                    url: "/project",
                } : {
                    id: 12,
                    label: "",
                    url: "/",
                },
            // roles?.includes('TechnoparkNonCustomers') ?
            //     {
            //         id: 13,
            //         label: "Teknopark Eklenmeyecek Müşteriler",
            //         url: "/teknoparkAddNotCustomer",
            //     } : {
            //         id: 13,
            //         label: "",
            //         url: "/",
            //     },
        ],
    },

    {
        id: 2,
        label: "İşe Giriş",
        url: "/",
        Icon: TrendingUp,
        submenu: [
            roles?.includes('TechnoparkEmployeeIntake') ?
                {
                    id: 21,
                    label: "İşe Giriş",
                    url: "/newJobStart",
                } : {
                    id: 21,
                    label: "",
                    url: "/",
                },
                {
                    id: 22,
                    label: "Personel",
                    url: "/personnel",
                } 
        ]
    },
    {
        id: 3,
        label: "Proje-Personel",
        url: "/",
        Icon: TrendingUp,
        submenu: [
            roles?.includes('TechnoparkProjectAssign') ?
                {
                    id: 31,
                    label: "Proje Atama",
                    url: "/projectAssignment",
                } : {
                    id: 31,
                    label: "",
                    url: "/",
                },
            roles?.includes('TechnoparkLeavingPersonel') ?
                {
                    id: 32,
                    label: "Ayrılan Personeller",
                    url: "/leavingPersonnel",
                } : {
                    id: 32,
                    label: "",
                    url: "/",
                },
        ]
    },
    {
        id: 4,
        label: "Rapor",
        url: "/",
        Icon: TrendingUp,
        submenu: [
            roles?.includes('TechnoparkReport') ?
                {
                    id: 41,
                    label: "Teknopark Rapor",
                    url: "/technoparkReport",
                } : {
                    id: 41,
                    label: "",
                    url: "/",
                }, 
        ]
    }


];

export default menus;
